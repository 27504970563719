import { useHistory } from 'react-router-dom'

import ImageGallery from '../../components/Product/ImageGallery'
import ProductDetails from '../../components/Product/ProductDetails'
import Recommandations from '../../components/Recommandation/Recommandations'

import './product.css'

export default function Product() {
  const router = useHistory()

  return (
    <div className='product-container'>
      <div className='mt-1 hr-line'></div>
      <div className='_container'>
        <p className='go-back-option' onClick={() => router.goBack()}>
          <span className='left-arrow'>&#8592;</span> <span>Go Back</span>
        </p>
        <div className='row'>
          <div className='col-5'>
            <ImageGallery />
          </div>
          <div className='col'>
            <ProductDetails />
          </div>
        </div>
        <hr />
        <Recommandations />
      </div>
    </div>
  )
}
