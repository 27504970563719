/* eslint-disable */
import { createContext, useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

export const AuthContext = createContext({})

const ContextProvider = ({ children }) => {
  const [auth, setAuth] = useState(false)
  const [openModalLogin, setOpenModalLogin] = useState(false)
  const [cookies] = useCookies(['Token'])

  useEffect(() => {
    if (cookies.Token !== undefined && auth === false) {
      fetch('https://mdt-hub-main.herokuapp.com/v1/auth/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: cookies.Token,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 200) {
            setAuth(true)
          }
        })
    }
  }, [])
  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        openModalLogin,
        setOpenModalLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default ContextProvider
