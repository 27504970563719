/* eslint-disable */
import { useState, useEffect, lazy, Suspense, useContext } from 'react'
import { Spin, Modal } from 'antd'
import { Link } from 'react-router-dom'
import CartTable from '../../components/Cart/CartTable'
import CartTotal from '../../components/Cart/Total'

import './cartpage.css'

import { AuthContext } from '../../context/AuthContext'

import Login from '../../components/login/login'

const PayOptions = lazy(() => import('../../components/Payment/PayOptions'))
const PayOptionsOne = lazy(() =>
  import('../../components/Payment/PayOptionsOne')
)

export default function CartPage() {
  const [bksh, setBksh] = useState(false)
  const [prdS, setPrds] = useState(undefined)
  const [bkshTan, setBkshTan] = useState(false)

  const { auth, openModalLogin, setOpenModalLogin } = useContext(AuthContext)

  const [items, setItems] = useState([
    {
      id: '5922b1d8-9d68-4dd9-8c41-9af4968f0305',
      imgSrc:
        'https://cdn.shopify.com/s/files/1/1320/1237/products/MUS_9598_grande.jpg?v=1633094514',
      name: "Paul Parkman Men's Penny Loafers Brown Suede",
      price: 5800,
    },
  ])

  const [itemsTan, setItemsTan] = useState([
    {
      id: '5922b1d8-9d68-4dd9-8c41-9af4968f0305',
      imgSrc:
        'https://cdn.shopify.com/s/files/1/1320/1237/products/MUS_9598_grande.jpg?v=1633094514',
      name: "Paul Parkman Men's Penny Loafers Brown Suede",
      price: 5,
    },
  ])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const _q = queryParams.get('_q')
    const bkash = queryParams.get('bkash')
    const prd = queryParams.get('prd')
    if (_q === 'cart' && bkash === '~true' && (prd === '0' || prd === null)) {
      setBksh(true)
    }

    if (_q === 'cart' && bkash === '~true' && prd === '1') {
      setPrds(prd)
      setBkshTan(true)
    }
  }, [])

  const openLogin = () => {
    setOpenModalLogin(true)
  }

  return (
    <Suspense fallback={<Spin />}>
      <Modal
        centered
        visible={openModalLogin}
        onOk={() => {
          setOpenModalLogin(false)
        }}
        onCancel={() => {
          setOpenModalLogin(false)
        }}
        closable={false}
        footer={null}
      >
        <Login />
      </Modal>
      <div className='cartpage__container'>
        <div className='container'>
          <div className='cart__title'>
            <p className='m-0'>Your Shopping Summary</p>
          </div>
          <hr />
          {bksh ? (
            items.length !== 0 ? (
              <>
                <div className='cart__options'>
                  <div className='continue__shopping'>
                    <Link to='/products'>
                      <span className='p-1'>Continue Shopping</span>
                    </Link>
                  </div>
                  {!auth ? (
                    <button
                      className='btn btn-dark'
                      onClick={() => openLogin()}
                    >
                      Paynow
                    </button>
                  ) : (
                    ''
                  )}
                </div>

                <div className='cart__cartproducts mt-4'>
                  <CartTable items={items} setItems={setItems} />
                </div>

                <div className='cart__total_with_all_cost'>
                  <CartTotal />
                </div>

                {auth && (
                  <div className='cart__paymnet_options'>
                    <PayOptions />
                  </div>
                )}
              </>
            ) : (
              <p className='text-muted text-center'>No Item found ...</p>
            )
          ) : (
            ''
          )}

          {bkshTan ? (
            itemsTan.length !== 0 ? (
              <>
                <div className='cart__options'>
                  <div className='continue__shopping'>
                    <Link to='/products'>
                      <span className='p-1'>Continue Shopping</span>
                    </Link>
                  </div>
                  {!auth ? (
                    <button
                      className='btn btn-dark'
                      onClick={() => openLogin()}
                    >
                      Paynow
                    </button>
                  ) : (
                    ''
                  )}
                </div>

                <div className='cart__cartproducts mt-4'>
                  <CartTable items={itemsTan} setItems={setItemsTan} />
                </div>

                <div className='cart__total_with_all_cost'>
                  <CartTotal prd={prdS} />
                </div>

                {auth && (
                  <div className='cart__paymnet_options'>
                    <PayOptionsOne />
                  </div>
                )}
              </>
            ) : (
              <p className='text-muted text-center'>No Item found ...</p>
            )
          ) : (
            ''
          )}

          <div className='support__div'></div>
        </div>
      </div>
    </Suspense>
  )
}
