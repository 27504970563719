import { useState, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { message } from 'antd'
import { Form } from 'react-bootstrap'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../config/firebase/init'
import { AuthContext } from '../../context/AuthContext'
import './login.css'

export default function Login() {
  const { setAuth, setOpenModalLogin } = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [btnDisableStatus, setBtnDisableStatus] = useState(false)
  /* eslint-disable */
  const [_, setCookie] = useCookies(['auth'])
  /* eslint-enable */
  const signinWithEmailAndPassword = async (event) => {
    setBtnDisableStatus(true)
    event.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCred) => {
        const user = userCred.user
        // console.log(user.uid)
        setCookie('Token', user.uid, { path: '/' })
        setAuth(true)
        message.success('success')
        setOpenModalLogin(false)

        /**
         * Changing user current login status
         */

        fetch(process.env.REACT_APP_PROD_USER_STATUS, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uid: user.uid,
            status: true,
          }),
        })
          .then((res) => res.json())
          .then((_) => {})
          .catch((err) => {
            console.log(err.message)
          })
      })
      .catch((err) => {
        setBtnDisableStatus(false)
        message.error('Please Provide Correct Credentials')
        console.log(err)
      })
  }

  return (
    <div className='login__container'>
      <span className='login_title'>
        log in <hr />
      </span>
      <form onSubmit={signinWithEmailAndPassword}>
        <Form.Floating className='mb-3'>
          <Form.Control
            id='floatingInputCustom'
            type='email'
            placeholder='name@example.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor='floatingInputCustom'>Email address</label>
        </Form.Floating>

        <Form.Floating>
          <Form.Control
            id='floatingPasswordCustom'
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label htmlFor='floatingPasswordCustom'>Password</label>
        </Form.Floating>

        <div className='d-flex justify-content-end'>
          <button
            className='btn btn-dark mt-2 w-100'
            type='submit'
            disabled={btnDisableStatus}
          >
            <span
              style={{
                fontSize: '20px',
                letterSpacing: '3px',
                textTransform: 'uppercase',
              }}
            >
              login
            </span>
          </button>
        </div>
        {/* <div
          className='text-center mt-1 __account_signup'
          onClick={() => {
            setOpenModalLogin(false)
            setOpenModalSignUP(true)
          }}
        >
          <span>Don't Have an account ? sign up.</span>
        </div> */}
      </form>
    </div>
  )
}
