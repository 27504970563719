import ReactDOM from 'react-dom'
import { CookiesProvider } from 'react-cookie'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import './gloabl.css'
import App from './_app'

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root')
)
