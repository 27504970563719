import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './landing.css'

function useTilt(active) {
  const ref = React.useRef(null)

  React.useEffect(() => {
    if (!ref.current || !active) {
      return
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    }

    let el = ref.current

    const handleMouseMove = (e) => {
      if (!el) {
        return
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect()
      }
      state.mouseX = e.clientX
      state.mouseY = e.clientY
      const px = (state.mouseX - state.rect.left) / state.rect.width
      const py = (state.mouseY - state.rect.top) / state.rect.height

      el.style.setProperty('--px', px)
      el.style.setProperty('--py', py)
    }

    el.addEventListener('mousemove', handleMouseMove)

    return () => {
      el.removeEventListener('mousemove', handleMouseMove)
    }
  }, [active])

  return ref
}

const initialState = {
  slideIndex: 0,
}

function Landing() {
  const history = useHistory()

  const [brands, setBrands] = useState([])

  function Slide({ slide, offset }) {
    const active = offset === 0 ? true : null
    const ref = useTilt(active)

    return (
      <div
        ref={ref}
        className='slide'
        data-active={active}
        style={{
          '--offset': offset,
          '--dir': offset === 0 ? 0 : offset > 0 ? 1 : -1,
        }}
      >
        <div
          className='slideBackground'
          style={{
            backgroundImage: `url('${slide.imgurl}')`,
          }}
        />
        <div
          className='slideContent'
          style={{
            backgroundImage: `url('${slide.imgurl}')`,
          }}
        >
          <div className='slideContentInner'>
            <h2 className='slideTitle ms-2'>{slide.brandName}</h2>
            <h3 className='slideSubtitle ms-2'>{slide.brandName}</h3>
            <p className='slideDescription ms-2'>{slide.brandName}</p>
            <div
              className='text-center btn-div'
              style={{ opacity: '0.8' }}
              onClick={() => {
                history.push({
                  pathname: `/products/brand/${slide.brandName}`,
                  state: {
                    brand: slide.brandName,
                  },
                })
              }}
            >
              <button className='btn btn-success'>View Products</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const slidesReducer = (state, event) => {
    if (event.type === 'NEXT') {
      return {
        ...state,
        slideIndex: (state.slideIndex + 1) % brands.length,
      }
    }
    if (event.type === 'PREV') {
      return {
        ...state,
        slideIndex:
          state.slideIndex === 0 ? brands.length - 1 : brands.slideIndex - 1,
      }
    }
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_PROD_BRANDS_API, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data)
        setBrands(res.data)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])

  const [state, dispatch] = React.useReducer(slidesReducer, initialState)

  return (
    <div className='landing__container'>
      <h3 className='text-center m-4 __Title'>Browser Throug Our Brands</h3>

      <div className='slide__container mt-1 '>
        <div className='slides'>
          <button onClick={() => dispatch({ type: 'PREV' })}>‹</button>

          {[...brands, ...brands, ...brands].map((slide, i) => {
            let offset = brands.length + (state.slideIndex - i)
            return <Slide slide={slide} offset={offset} key={i} />
          })}
          <button onClick={() => dispatch({ type: 'NEXT' })}>›</button>
        </div>
      </div>
    </div>
  )
}

export default Landing
