import { BrowserRouter, Switch, Route } from 'react-router-dom'

/** Global Componets */
import Header from './components/Header/Header'
import Navbar from './components/navs/_navbar'

/** Pages */
import Landing from './pages/Landing/Landing'
import Products from './pages/Products/Products'
import Product from './pages/Product/Product'
import Cart from './pages/Cart/CartPage'
import Porfile from './pages/Profile/Porfile'
import Payment from './pages/Payment/Payment'

import AuthContext from './context/AuthContext'
import ProductsWithBrands from './pages/Products/ProductsWithBrands'

export default function App() {
  return (
    <AuthContext>
      <BrowserRouter>
        <Header />
        <Navbar />
        <Switch>
          <Route path='/' exact component={Landing} />
          <Route path='/products' exact component={Products} />
          <Route
            path='/products/brand/:brand'
            exact
            component={ProductsWithBrands}
          />
          <Route path='/product/:id' exact component={Product} />
          <Route path='/cart' exact component={Cart} />
          <Route path='/account' exact component={Porfile} />
          <Route path='/payments' exact component={Payment} />
        </Switch>
      </BrowserRouter>
    </AuthContext>
  )
}
