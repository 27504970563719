import { DeleteOutlined } from '@ant-design/icons'
import './cartTable.css'

export default function CartTable({ items, setItems }) {
  const removeItemFromCart = (itemId) => {
    setItems((prev) => prev.filter((item) => item.id !== itemId))
  }

  return (
    <div className='cart__table'>
      <table width='100%' className='text-center'>
        <thead>
          <tr>
            <th className='p-3 w-50'>Product Details</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            return (
              <tr key={item.id}>
                <td className='p-3 w-50'>
                  <div className='row container-fluid'>
                    <div className='col-2'>
                      <img
                        src={item.imgSrc}
                        alt='shoe'
                        className='cart__pord__image'
                      />
                    </div>
                    <div className='col-10 d-flex align-items-center justify-content-center'>
                      <span className='prod__title float-start'>
                        {item.name}
                      </span>
                    </div>
                  </div>
                </td>
                <td>1</td>
                <td>
                  {new Intl.NumberFormat('ja-JP', {
                    style: 'currency',
                    currency: 'BDT',
                  }).format(item.price)}
                </td>
                <td style={{ fontSize: '20px', color: 'gray' }}>
                  <div className='d-flex justify-content-center align-items-center __del__item__from_cart'>
                    <DeleteOutlined
                      onClick={() => removeItemFromCart(item.id)}
                    />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
