import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './products.css'

export default function ProductsWithBrands({ location: { state } }) {
  const [products, setProducts] = useState([])

  const history = useHistory()

  useEffect(() => {
    if (state === undefined) {
      history.push('/')
    } else {
      fetch(
        `${process.env.REACT_APP_PROD_PRODUCTS_BY_BRAND_API}${state.brand}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res.data)
          setProducts(res.data)
        })
    }
  }, [history, state])

  return (
    <div className='product-main'>
      <hr />
      <div className='container'>
        <div className='_title'>All Products</div>
        <div className='row'>
          {products.map((product, index) => (
            <div className='col-md-4 mb-4' key={index}>
              <div className='item-container'>
                <img
                  src={product.imgs[0]}
                  alt={product.imgRefName[0]}
                  style={{
                    height: '250px',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
                <Link className='product-links' to={`/product/${product.id}`}>
                  <h5 className='mt-2'>
                    {product.name}
                    <br />
                    (product id # {product.id.substring(0, 9)})
                  </h5>
                  <span className='product-price'>৳ {product.price}</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
