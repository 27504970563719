import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Select, Button } from 'antd'
import './productDetails.css'

const { Option } = Select

const provinceData = [
  'EU 38 - US 6',
  'EU 39 - US 6.5 / 7',
  'EU 40 - US 7.5',
  'EU 41 - US 8 / 8.5',
  'EU 42 - US 9 / 9.5',
  'EU 43 - US 9.5 / 10',
]

export default function ProductDetails() {
  /*eslint-disable*/
  const [size, setSize] = useState(provinceData[0])
  /*eslint-enable*/

  const handleProvinceChange = (value) => {
    setSize(provinceData[value])
  }

  return (
    <div className='pd-container'>
      <div className='pd_title'>
        <span>Genuine Iguana Cap Toe Derby Shoes (ID#582BLK72)</span>
        <hr style={{ width: '80%' }} />
      </div>
      <div className='pd_price'>
        <span>
          Price - ৳ 5000{' '}
          <span className='text-muted' style={{ fontSize: '20px' }}>
            per pair
          </span>{' '}
        </span>
      </div>
      <div className='pd_deatils text-muted'>
        <span>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae
          quos quibusdam nihil consectetur enim tenetur cum laborum incidunt,
          praesentium ab?
        </span>
        <ul>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
        </ul>
      </div>

      <div className='pd_size'>
        <div className='row w-50'>
          <div className='col'>
            <p className='text-muted m-0 p-0'>Select Your Size</p>
          </div>
          <div className='col'>
            <p className='text-muted m-0 p-0 text-center'>Quantity</p>
          </div>
        </div>
        <div className='row w-50'>
          <div className='col'>
            <Select
              defaultValue={provinceData[0]}
              style={{ width: 160 }}
              onChange={handleProvinceChange}
            >
              {provinceData.map((province) => (
                <Option key={province}>{province}</Option>
              ))}
            </Select>
          </div>

          <div className='col'>
            <div className='row text-center'>
              <div className='col-4'>
                <Button size='large' style={{ width: '100%' }}>
                  +
                </Button>
              </div>
              <div className='col-4'>
                <input
                  type='text'
                  value={10}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '18px',
                    border: '#d9d9d9 1px solid',
                  }}
                  disabled
                />
              </div>
              <div className='col-4'>
                <Button size='large' style={{ width: '100%' }}>
                  -
                </Button>
              </div>
            </div>
          </div>

          <div className='pd-availability mt-3'>
            <p className='m-0 p-0' style={{ fontSize: '18px' }}>
              Product Status -{' '}
              <span style={{ textTransform: 'uppercase', color: 'green' }}>
                available
              </span>{' '}
            </p>
          </div>

          <div className='pd_book mt-4 d-flex'>
            <Link
              to='/cart'
              className='btn btn-dark btn-lg'
              style={{ width: '300px' }}
            >
              Buy Now
            </Link>
            <button
              className='btn btn-dark btn-lg ms-2'
              style={{ width: '300px' }}
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
