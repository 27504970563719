import Carousel, { Dots } from '@brainhubeu/react-carousel'

import '@brainhubeu/react-carousel/lib/style.css'
import './imageGallery.css'

import { useState } from 'react'

export default function ImageGallery() {
  const [value, setValue] = useState(0)

  const onChange = (value) => {
    setValue(value)
  }
  return (
    <div className='ig-container'>
      <Carousel value={value} onChange={onChange}>
        <img
          className='img-example'
          src={
            'https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2hvZXN8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80'
          }
          alt='al'
        />
        <img
          className='img-example'
          src={
            'https://cdn.shopify.com/s/files/1/1320/1237/products/MUS_3518_1024x1024.jpg?v=1633094904'
          }
          alt='al'
        />
      </Carousel>
      <Dots
        value={value}
        onChange={onChange}
        className='dots'
        thumbnails={[
          <img
            key={1}
            className='img-example-small'
            src={
              'https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2hvZXN8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80'
            }
            alt='al'
          />,
          <img
            key={12}
            className='img-example-small'
            src={
              'https://cdn.shopify.com/s/files/1/1320/1237/products/MUS_3518_1024x1024.jpg?v=1633094904'
            }
            alt='al'
          />,
        ]}
      />
    </div>
  )
}
