import { useState } from 'react'
import { Link } from 'react-router-dom'

import './recommandations.css'

export default function Recommandations() {
  const [slides] = useState([{}, {}, {}])

  return (
    <div className='recommandation-container'>
      <div className='rc__title__view_all'>
        <div className='rc__title text-center'>
          <span className='mx-0 my-0 p-0'>Recommanded Products</span>
        </div>
        <div className='rc__view_all'>
          <Link to='/products'>
            <button>View All</button>
          </Link>
        </div>
      </div>
      <div className='product-main'>
        <div className='mt-4'>
          <div className='row'>
            {slides.map((s, index) => (
              <div className='col-md-4 mb-4' key={index}>
                <div className='item-container'>
                  <img
                    src='https://cdn.shopify.com/s/files/1/1320/1237/products/MUS_9598_grande.jpg?v=1633094514'
                    alt='shoe01'
                  />
                  <Link
                    className='product-links'
                    to='/product/af803eba-a920-49ea-b410-846fd768a73d'
                  >
                    <span className='text-muted'>
                      Paul Parkman Men's Smart Casual Penny Loafers Camel Suede
                      (ID#180-CML-SD)
                    </span>
                    <span className='product-price'>৳ 4000</span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
