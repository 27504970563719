import './total.css'

export default function Total({ prd }) {
  return (
    <div className='cart__total__container'>
      <div className='row'>
        <div className='col-lg-6 col-md-0'></div>
        {prd === '1' ? (
          <div className='col-md-12 col-lg-6'>
            <div className='container container__ d-flex justify-content-between'>
              <span>Sub Total</span>
              <span>
                {' '}
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'BDT',
                }).format(5)}
              </span>
            </div>
            <div className='container container__ d-flex justify-content-between'>
              <span>Vat</span>
              <span>
                {' '}
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'BDT',
                }).format(0)}
              </span>
            </div>
            <div className='container container__ d-flex justify-content-between'>
              <span>Delivery Charge</span>
              <span>
                {' '}
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'BDT',
                }).format(0)}
              </span>
            </div>
            <hr className='dotted' />
            <div className='container container__ d-flex justify-content-between'>
              <span>Total</span>
              <span>
                {' '}
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'BDT',
                }).format(5)}
              </span>
            </div>
          </div>
        ) : (
          <div className='col-md-12 col-lg-6'>
            <div className='container container__ d-flex justify-content-between'>
              <span>Sub Total</span>
              <span>
                {' '}
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'BDT',
                }).format(5800)}
              </span>
            </div>
            <div className='container container__ d-flex justify-content-between'>
              <span>Vat</span>
              <span>
                {' '}
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'BDT',
                }).format(870)}
              </span>
            </div>
            <div className='container container__ d-flex justify-content-between'>
              <span>Delivery Charge</span>
              <span>
                {' '}
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'BDT',
                }).format(60)}
              </span>
            </div>
            <hr className='dotted' />
            <div className='container container__ d-flex justify-content-between'>
              <span>Total</span>
              <span>
                {' '}
                {new Intl.NumberFormat('ja-JP', {
                  style: 'currency',
                  currency: 'BDT',
                }).format(6730)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
