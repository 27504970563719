import { Link } from 'react-router-dom'
import './navabar.css'

const Nav = () => {
  return (
    <div className='navbar-container'>
      <div className='nav-main'>
        <Link to='/' className='react_links'>
          home
        </Link>
        <Link to='/products' className='react_links'>
          <span>All Products</span>
        </Link>
        <Link to='/' className='react_links'>
          New Arrivals
        </Link>
        <Link to='/' className='react_links'>
          About
        </Link>
        <Link to='/' className='react_links'>
          Size Guide
        </Link>
        <Link to='/' className='react_links'>
          Contact
        </Link>
      </div>
    </div>
  )
}

export default Nav
