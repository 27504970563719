import { useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useHistory } from 'react-router-dom'
import { Badge, Menu, Dropdown, Modal } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { CgProfile } from 'react-icons/cg'
import { AiOutlineLogout } from 'react-icons/ai'
import { GrTransaction } from 'react-icons/gr'
import './header.css'

import { AuthContext } from '../../context/AuthContext'
import Login from '../login/login'

const Header = () => {
  const { auth, setAuth, openModalLogin, setOpenModalLogin } =
    useContext(AuthContext)
  /* eslint-disable */
  const [cokkie, __, removeCookie] = useCookies(['Token'])
  /* eslint-enable */
  const history = useHistory()

  function loginmodal() {
    setOpenModalLogin(true)
  }

  function handleMenuClick(e) {
    switch (e.key) {
      case 'logout':
        const token = cokkie.Token
        console.log(token)
        fetch(process.env.REACT_APP_PROD_USER_STATUS, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uid: token,
            status: false,
          }),
        })
          .then((res) => res.json())
          .then((_) => {})
          .catch((err) => {
            console.log(err.message)
          })
        removeCookie('Token')
        setAuth(false)
        return
      case 'payment-history':
        history.push('/payments')
        return
      default:
        return
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key='payment-history'
        icon={<GrTransaction style={{ fontSize: '15px' }} />}
      >
        Transaction History
      </Menu.Item>
      <Menu.Item
        key='logout'
        icon={<AiOutlineLogout style={{ fontSize: '15px' }} />}
      >
        Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Modal
        centered
        visible={openModalLogin}
        onOk={() => {
          setOpenModalLogin(false)
        }}
        onCancel={() => {
          setOpenModalLogin(false)
        }}
        closable={false}
        footer={null}
      >
        <Login />
      </Modal>

      <div className='container-fluid'>
        <div className='row header__container'>
          <div className='col-2'></div>
          <div className='col-8 text-center __brand_name my-auto'>
            <span>style</span>
          </div>
          <div className='col-1 __carts'>
            <Link to='/cart'>
              <Badge count={1} overflowCount={5}>
                <ShoppingCartOutlined
                  className='cart__icon'
                  style={{ fontSize: '35px' }}
                />
              </Badge>
            </Link>

            {auth ? (
              <Dropdown.Button
                overlay={menu}
                icon={<CgProfile style={{ fontSize: '25px' }} />}
              ></Dropdown.Button>
            ) : (
              <span className='signup_login' onClick={() => loginmodal()}>
                signin
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
