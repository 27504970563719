import { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Spin, Table } from 'antd'

import { AuthContext } from '../../context/AuthContext'

const Payment = () => {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const [cookie] = useCookies(['Token'])
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'trxId',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'Amount',
    },
    {
      title: 'date',
      dataIndex: 'created_at',
      key: 'date',
    },
  ]

  /* eslint-disable */

  useEffect(() => {
    if (auth) {
      fetch('https://mdt-hub-main.herokuapp.com/v1/g-history', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          uid: cookie.Token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data)
          setLoading(false)
        })
    } else {
      history.push('/')
    }
  }, [])

  /* eslint-enable */

  return (
    <div>
      {loading ? (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin tip='loading' />
        </div>
      ) : (
        <div className='container'>
          <h3 className='mt-4 text-center'>Transaction History </h3>
          <Table
            columns={columns}
            dataSource={data}
            style={{ textAlign: 'center' }}
          />
        </div>
      )}
    </div>
  )
}

export default Payment
