import './profile.css'

export default function Porfile() {
  return (
    <div className='account__profile'>
      <div className='container'>
        <div className='__order_lists__container'>
          <div className='__order_list_title'>
            <span>Order History</span>
          </div>
          <div className='__order__history text-muted'>
            <span>You Didn't make any order Yet.</span>
          </div>
        </div>

        <div className='__profile'>
          <div className='__profile_title'>
            <span>Proflie</span>
            <button className='btn border-bottom float-end'>
              Edit Address
            </button>
          </div>
          <div className='__profile_container mt-4'>
            <div className='container'>
              <div className='account__address'>
                Address -
                <br />
                Bangladesh Office B-25,
                <br />
                Mannan Plaza, 4th Floor Khilkhet,
                <br />
                Dhaka-1229, Bangladesh
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
